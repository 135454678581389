import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submit() {
    const form = this.element.closest('form');
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const formData = new FormData(form);
    formData.append('authenticity_token', csrfToken);

    const payload = {
      Accept: 'text/vnd.turbo-stream.html',
      method: form.method,
      body: formData
    };

    fetch(form.action, payload)
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html));
  }

}

