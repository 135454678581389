import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  click(e) {
    const target = e.target;
    const allAnswers = document.querySelectorAll(
      `[data-question-group-name="${target.dataset.questionGroupName}"]`
    );
    const targetAnswerValue = parseInt(target.dataset.answerValue);
    const checkedIconTypes = target.dataset.checkedIconTypes.split(' ');
    const uncheckedIconType = target.dataset.uncheckedIconType;

    this.updateHoverDefaultText(target);

    allAnswers.forEach((answer) => {
      answer.classList.remove(...answer.dataset.checkedIconTypes.split(' '));
      answer.classList.add(answer.dataset.uncheckedIconType);

      const answerValue = parseInt(answer.dataset.answerValue);
      if (targetAnswerValue > 0 && answerValue == 0) {
        return;
      }
      if (targetAnswerValue >= answerValue) {
        answer.classList.remove(uncheckedIconType);
        answer.classList.add(...checkedIconTypes);
      } else {
        answer.classList.remove(...checkedIconTypes);
        answer.classList.add(uncheckedIconType);
      }
    });
  }

  updateHoverDefaultText(target) {
    const btnContainer = target.closest('.js-rating-btn-container');
    btnContainer.querySelectorAll('button').forEach((button) => {
      if (button.dataset.defaultText && target.dataset.hoverText) {
        button.dataset.defaultText = target.dataset.hoverText;
      }
    });
  }
}
